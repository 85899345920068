import { AlgoliaPersonalisation, Department, ProductSearchState } from './types';
import { useContext, useEffect, useState } from 'react';
import { equals } from 'ramda';
import { useRouter } from 'next/compat/router';
import { algoliaIndices } from '../../../utils/constants';
import { useCustomer } from '../../account/Payout/useCustomer';
import { AccountContext } from '../../../context/AccountContext';
import Wishlist from '../../storyblok/nested/Drawer/Wishlist';
import { WishlistContext } from '../../../context/WishlistContext';
import { useCookies } from 'react-cookie';

const tryParseInt = (text: any, fallback: number) => {
  if (typeof text !== 'string') return fallback;
  const num = parseInt(text, 10);
  if (Number.isNaN(num)) return fallback;
  return num;
}

const tryParseString = (text: any, fallback: string) => {
  if (typeof text !== 'string') return fallback;
  return text;
}

const parseQuery = (urlSearchParams: URLSearchParams): Record<string, string | string[]> => {
  let query: Record<string, string | string[]> = {};
  const iterator = urlSearchParams.entries();
  while (true) {
    const next = iterator.next();
    if (next.done) return query;

    const [key, value] = next.value;
    const existingValue = query[key];
    if (typeof existingValue === 'undefined') query[key] = value;
    else if (typeof existingValue === 'string') query[key] = [existingValue, value];
    else if (Array.isArray(existingValue)) query[key] = [...existingValue, value];
  }
}

const parseCollectionName = (pathName: string | null): string | undefined => {
  if (typeof pathName !== 'string') return undefined;
  // remove leading and trailing slashes and split into segments
  const pathSegments = pathName.replace(/^\//, '').replace(/\/$/, '').split('/');
  if (pathSegments.length === 2) {
    // normal collections are /{department}/collection_name
    // search is /{department}/search, so exclude this case
    if (pathSegments[1] === 'search') return;
    return pathSegments.join('-')
  } else if(pathSegments.length === 1) {
    // partner collections are just one segment
    return pathSegments[0]
  }
}

export const parseEnum = (queryParam: any, options: string[]): string | undefined => {
  if (typeof queryParam !== 'string') return undefined;
  if (options.includes(queryParam)) return queryParam;
  return undefined;
}

export const parseSearchStateFromURL = (url:string | null, cookies?: Record<string, string | undefined>): ProductSearchState | null => {
  if (!url) return null;
  try {
    const urlObj = new URL(url);
    const queryParams = parseQuery(new URLSearchParams(urlObj.search));
    const department = url.includes('/men/') ? Department.Mens : url.includes('/women/') ? Department.Womens : undefined;

    return {
      department,
      pagination: {
        pageSize: 50,
        page: tryParseInt(queryParams.page, 0)
      },
      personalisation: {
        userToken: cookies?.wishlistSessionId,
        authenticatedUserToken: cookies?.customerId,
      },
      query: tryParseString(queryParams.q, ''),
      brand: queryParams.brand,
      brand_type: queryParams.brand_type,
      category: queryParams.category,
      colour: queryParams.colour,
      condition: queryParams.condition,
      material: queryParams.material,
      original_tags: queryParams.original_tags,
      size: queryParams.size,
      style: queryParams.style,
      price_range: queryParams.price_range,
      collections: parseCollectionName(urlObj.pathname),
      sort: parseEnum(queryParams.sort, [algoliaIndices.shopifyProducts, algoliaIndices.shopifyProductsPriceAsc, algoliaIndices.shopifyProductsPriceDesc, algoliaIndices.shopifyProductsNewest, algoliaIndices.shopifyProductsPersonalisationTests])
    }
  } catch (err) {
    return null;
  }
}

type Options = {
  serverUrl?: string,
}

export const useSearchState = (options?: Options): [ProductSearchState | null] => {
  const serverUrl = options?.serverUrl;
  const windowExists = typeof window === 'object';
  const url = windowExists ? window.location.href : serverUrl ?? null;
  const [cookies] = useCookies(['wishlistSessionId', 'customerId']);
  const [state, setState] = useState(parseSearchStateFromURL(url, cookies));

  const newState = parseSearchStateFromURL(url, cookies);
  if (!equals(state, newState)) setState(newState);

  return [state];
}


