import { GetProductSingleQuery, ShopifyService } from '../modules/shopify.service';
import { useQuery } from '@tanstack/react-query';
import { capitalizeFirstLetter } from '../utils/helpers';
import { CanonicalItem, generateSizeDisplayString, generateTitleDisplayString } from './canonicalItem';
import { getQueryClient } from '../context/QueryContext';

const extractSizeFromTags = (tags: string[]): CanonicalItem["size"] => {
  const rawSizes: Record<string, string> = tags
    .filter(tag => tag.startsWith('size:'))
    .map(tag => tag.replace('size:', '').trim().toLowerCase())
    .reduce((sizes, tag) => { 
      const [aspect, measurement] = tag.split('_');
      
      return {
        ...sizes,
        [aspect.trim()]: (measurement.trim())
      }
    }, {});
  
  let sizes: CanonicalItem["size"] = { display: ''};
  
  if (rawSizes.height && rawSizes.width && rawSizes.length) {
    sizes.dimensions = {
      height: parseFloat(rawSizes.height),
      width: parseFloat(rawSizes.width),
      length: parseFloat(rawSizes.length),
    }
  }

  const sizeMappings: Record<string, keyof CanonicalItem["size"]> = {
    shoeuk: 'uk_shoe',
    shoeeu: 'eu_shoe',
    uk: 'uk',
    waist: 'waist',
    leg: 'leg',
    collar: 'collar',
    chest: 'chest',
    sml: 'sml'
  };

  Object.entries(rawSizes).forEach(([key, value]) => {
    // @ts-ignore
    sizeMappings[key] && (sizes[sizeMappings[key]] =
        key === 'sml' ? value.toUpperCase() : parseFloat(value));
  });


  return sizes;
}

const extractSizeFromOldStyleTags = (tags: string[]): CanonicalItem["size"] => {
  const rawSizes: Record<string, string> = tags
    .filter(tag => tag !== undefined)
    .filter(tag => tag.startsWith('Size'))
    .map(tag => tag.trim().toLowerCase().slice('size'.length))
    .reduce((sizes, tag) => {
      const [aspect, measurement] = tag.split('_');
      if (measurement.trim().length === 0) return sizes;
      return {
        ...sizes,
        [aspect.trim()]: (measurement.trim())
      }
    }, {});

  let sizes: CanonicalItem["size"] = { display: ''};

  if (rawSizes.height && rawSizes.width && rawSizes.length) {
    sizes.dimensions = {
      height: parseFloat(rawSizes.height),
      width: parseFloat(rawSizes.width),
      length: parseFloat(rawSizes.length),
    }
  }

  const sizeMappings: Record<string, keyof CanonicalItem["size"]> = {
    shoeuk: 'uk_shoe',
    shoeeu: 'eu_shoe',
    uk: 'uk',
    waist: 'waist',
    leg: 'leg',
    collar: 'collar',
    chest: 'chest',
    sml: 'sml'
  };

  Object.entries(rawSizes).forEach(([key, value]) => {
    // @ts-ignore
    sizeMappings[key] && (sizes[sizeMappings[key]] =
      key === 'sml' ? value.toUpperCase() : parseFloat(value));
  });


  return sizes;
}

const fetchShopifyItemByHandle = async ({ handle }: {handle: string}) => {
  const { productByHandle } = await ShopifyService.getProductSingle({ handle });
  return productByHandle;
}

const getGenericFromTags = (tags: string[], tagName: string): string => {
  const tagPrefix = `${tagName}:`;
  const tagValue = tags.find(tag => tag.includes(tagPrefix));
  return tagValue ? tagValue.split(tagPrefix)[1].trim() : (getSpecificFromTags(tags, capitalizeFirstLetter(tagName)) ?? ''); // should always be present, but fallback to the underscore method for old items
}

const getSpecificFromTags = (tags: string[], tagName: string): string | undefined => {
  const tagPrefix = `${tagName}_`;
  const tagValue = tags.find(tag => tag.includes(tagPrefix));
  return tagValue ? tagValue.split(tagPrefix)[1].trim() : undefined;
}

const tryParseFloat = (text?: string): number | undefined => {
  if (!text) return undefined;
  const parsedFloat = Number.parseFloat(text);
  if (Number.isNaN(parsedFloat)) return undefined;
  return parsedFloat;
}

export const convertShopifyProductToCanonicalItem = (shopifyProduct: GetProductSingleQuery['productByHandle']): CanonicalItem | undefined => {
  if (!shopifyProduct) return undefined;

  const brand = getGenericFromTags(shopifyProduct.tags, "brand");
  const size = extractSizeFromTags(shopifyProduct.tags);
  const fallbackSize = extractSizeFromOldStyleTags(shopifyProduct.tags);

  const canonicalItem: CanonicalItem = {
    id: Number.parseInt(shopifyProduct.id.split('/').slice(-1)[0]),
    title: shopifyProduct.title,
    display_title: '',
    handle: shopifyProduct.handle,
    photos: shopifyProduct.images?.edges?.map(edge => edge.node.url),
    department: getGenericFromTags(shopifyProduct.tags, "department"),
    brand: ['unknown', 'unbranded'].includes(brand.toLowerCase()) ? undefined : brand,
    brand_type: getGenericFromTags(shopifyProduct.tags, "brand_type"),
    colour: getGenericFromTags(shopifyProduct.tags, "colour"),
    condition: getGenericFromTags(shopifyProduct.tags, "condition")
    .split('_').join(' '),
    style: getGenericFromTags(shopifyProduct.tags, "style"),
    category: getGenericFromTags(shopifyProduct.tags, "category"),
    size: Object.keys(size).length > 1 ? size : fallbackSize, // always a 'display' size, even if blank string, so check greater than 1,
    seller: getGenericFromTags(shopifyProduct.tags, "seller"),
    materials: shopifyProduct.tags
      .filter((tag) => tag.startsWith('Material_'))
      .map((tag) => tag.toLowerCase().replace('material_', '').replace(/_/g, ''))
      .flatMap(tag => tag.split('-'))
      .map(material => material.trim())
      .map(capitalizeFirstLetter),
    original_tags: getGenericFromTags(shopifyProduct.tags, "original_tags:boolean") === 'true',
    total_inventory: shopifyProduct.totalInventory ?? 0,
    in_stock: shopifyProduct.totalInventory && shopifyProduct.totalInventory > 0 ? true : false,
    defects: shopifyProduct.tags
      .filter((tag) => tag.indexOf('Defect') > -1)
      .map((tag) => tag.toLowerCase().replace('defect_', '').replace(/_/g, ' '))
      .map(capitalizeFirstLetter),
    collections: shopifyProduct?.collections?.edges.map((edge) => edge.node.handle) ?? [],
    sku: getGenericFromTags(shopifyProduct.tags, "sku"),
    strikethrough_price: tryParseFloat(shopifyProduct.variants?.edges?.[0].node?.compareAtPriceV2?.amount),
    variant_id: Number.parseInt(shopifyProduct.variants.edges[0].node.id.split('/').slice(-1).join('')),
    price: Number.parseFloat(shopifyProduct.variants?.edges?.[0]?.node?.priceV2?.amount),
    pattern: getGenericFromTags(shopifyProduct.tags, "pattern"),
    neckline: getSpecificFromTags(shopifyProduct.tags, 'Neckline'),
    sleeve_length: getSpecificFromTags(shopifyProduct.tags, 'SleeveLength'),
    dress_length: getSpecificFromTags(shopifyProduct.tags, 'DressLength'),
    leg_style: getSpecificFromTags(shopifyProduct.tags, 'LegStyle'),
    listed_at: shopifyProduct.tags.find(tag => tag.includes('listed_at:number')) ? new Date(parseInt(getGenericFromTags(shopifyProduct.tags, 'listed_at:number'))).toISOString() : null,
    _original: shopifyProduct
  };
  canonicalItem.size.display = generateSizeDisplayString(canonicalItem);
  canonicalItem.display_title = generateTitleDisplayString(canonicalItem);

  // this is a bit annoying, but useServerSideProps requires all keys to be removed if the value is undefined
  // so the easiest way to do this is to serialise and deserialise the item to remove the undefined keys
  const canonicalItemWithUndefinedValuesRemoved = JSON.parse(JSON.stringify(canonicalItem));
  return canonicalItemWithUndefinedValuesRemoved
}

export const useShopifyItem = (handle: string, options?: { initialData?: CanonicalItem | null, alwaysFresh?: boolean }) => {
  const { isLoading, error, data } = useQuery({
      queryKey: ['items', handle],
      queryFn: ({ queryKey }) => {
        const handle = queryKey[1];
        if (['thriftbox','thriftbag'].includes(handle)) return null;
        return fetchShopifyItemByHandle({ handle: queryKey[1] })
          .then(convertShopifyProductToCanonicalItem);
      },
      ...options,
      refetchOnReconnect: true,
      staleTime: options?.alwaysFresh ? 1000 : 1000 * 60 * 5, // 1 second or 5 mins,
      gcTime: 1000 * 60 * 5,
      refetchOnWindowFocus: true,
      refetchInterval: false,
      refetchOnMount: true,
      refetchIntervalInBackground: false,
    },
    getQueryClient()
  );

  return { isItemLoading: isLoading, error, item: data };
}