import React, { useContext } from 'react';

import { Icon } from '../../core';

import Shop from '../../storyblok/nested/Drawer/Shop';
import Wishlist from '../../storyblok/nested/Drawer/Wishlist';

import { DrawerContext } from '../../../context/DrawerContext';
import { WebviewEventContext } from '../../../context/WebviewEventContext';
import { Logo } from '../../core/Icons/iconList';

import styles from './styles.module.css';
import classNames from 'classnames';
import { AccountContext } from '../../../context/AccountContext';
import { SessionContext } from '../../../context/SessionContext';
import { CartContext } from '../../../context/CartContext';
import { WishlistContext } from '../../../context/WishlistContext';
import { Transition } from '@headlessui/react';
import { ISbStoryData, StoryblokComponent } from '@storyblok/react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { CategoriesHeader } from './CategoriesHeader';
import { ConfigStoryblok } from '../../../component-types-sb';

export const HeaderTooltip = ({ isVisible, text, isWishlist }: { isVisible: boolean; text: string; isWishlist?: boolean }) => {
  const { isMobileApp } = useContext(WebviewEventContext);

  return (
    <div
      className={classNames(styles.HeaderTooltipWrapper, {
        [styles.HeaderTooltipWrapperApp]: isMobileApp,
        [styles.HeaderTooltipWrapperWishlist]: isWishlist,
      })}
    >
      <Transition
        show={isVisible}
        enter="transition-opacity duration-200 delay-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0 z-2"
      >
        <div className={`${styles.HeaderTooltip} body-14-bold`}>{text}</div>
      </Transition>
    </div>
  );
};

export const Header = ({ config }) => {
  const { isLoggedInDynamic, reviewableItemCount } = useContext(AccountContext);
  const { showDrawer } = useContext(DrawerContext);
  const { openCart, cart, showCartHeaderTooltip } = useContext(CartContext);
  const { wishlist, showWishlistHeaderTooltip } = useContext(WishlistContext);
  const { isMobileApp } = useContext(WebviewEventContext);
  const { basePath } = useContext(SessionContext);
  const announcement = config.content.global_announcement;
  const router = useRouter();

  const handleOpenShopDrawer = () => {
    showDrawer(Shop as any, { config });
  };

  const handleOpenCart = () => {
    openCart();
  };

  const handleOpenWishlist = () => {
    showDrawer(Wishlist as any, { alignment: 'right', wideScreen: true });
  };

  return (
    <>
      {isMobileApp && (
        <>
          <HeaderTooltip isVisible={showCartHeaderTooltip} text="Added to your bag" />
          <HeaderTooltip isVisible={showWishlistHeaderTooltip} isWishlist text="Added to your watchlist" />
        </>
      )}
      <nav className={classNames(styles.Header)}>
        {announcement?.map((nestedBlok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
        <div className="Container">
          <div className={classNames(styles.HeaderContainer, 'Section')}>
            <div className={styles.HeaderLeft}>
              <button className={styles.HeaderLink} onClick={handleOpenShopDrawer}>
                <Icon title="hamburger" width={24} height={18} className={styles.HeaderLinkIcon} />
                <span className="body-14-bold hidden md:inline-block">Browse</span>
              </button>
              <div className="hidden md:flex">
                <Link href={basePath} className={`${styles.HeaderLink} px-4 ${(router.asPath.includes(basePath) || router.asPath.includes('/products') || router.asPath.includes('/designer-store')) && styles.ActiveTab}`}>
                  <span className="body-14-bold">Shop</span>
                </Link>
                <Link href="/clearout" className={`${styles.HeaderLink} px-4 ${router.asPath.includes('/clearout') && styles.ActiveTab}`}>
                  <span className="body-14-bold">Sell</span>
                </Link>
              </div>
            </div>
            <div className={styles.HeaderCenter}>
              <Link href={basePath} className="inline-block m-0">
                <Logo width={120} />
              </Link>
            </div>

            <div className={styles.HeaderRight}>
              <Link href="/account/login" className={styles.HeaderLink}>
                <div className="relative z-2">
                  <Icon title="user" className={styles.HeaderLinkIcon} />
                  {reviewableItemCount > 0 ? (
                    <div className="absolute -top-[5px] right-[2px] bg-black text-white w-4 h-4 body-10-bold rounded-lg pt-[1px] text-center ">
                      {reviewableItemCount}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <span className="body-14-bold hidden lg:inline-block">
                  {isLoggedInDynamic ? 'My Account' : 'Login'}
                </span>
              </Link>

              <button onClick={handleOpenWishlist} className={styles.HeaderLink}>
                <div className="relative z-2">
                  <Icon title="heart" className={styles.HeaderLinkIcon} />
                  {!!wishlist?.listcontents?.length && [1, 2].includes(wishlist.listcontents?.length.toString().length) ? (
                    <div className="absolute -top-[5px] right-[2px] bg-black text-white w-4 h-4 body-10-bold rounded-lg pt-[1px] ">
                      {wishlist?.listcontents?.length}
                    </div>
                  ) :
                  !!wishlist?.listcontents?.length && wishlist.listcontents?.length.toString().length > 2 ?
                  <div className="absolute -top-[11px] right-[-4px] bg-black text-white body-10-bold rounded-xl">
                    <div className="flex justify-center items-center p-1">
                      {wishlist?.listcontents?.length}
                    </div>
                  </div>
                  : (
                    <></>
                  )}
                  {!isMobileApp && <HeaderTooltip isVisible={showWishlistHeaderTooltip} text="Added to your watchlist" />}
                </div>
                <span className="body-14-bold hidden lg:inline-block">Watchlist</span>
              </button>

              <button className={styles.HeaderLink} onClick={handleOpenCart}>
                <div className="relative">
                  <Icon title="bag" className={styles.HeaderLinkIcon} />
                  {!!cart?.items?.length ? (
                    <div className="absolute top-0 right-[2px] bg-black text-white w-4 h-4 body-10-bold rounded-lg pt-[1px] ">
                      {cart?.items?.length}
                    </div>
                  ) : (
                    <></>
                  )}
                  <HeaderTooltip isVisible={showCartHeaderTooltip} text="Added to your bag" />
                </div>
                <span className="body-14-bold hidden lg:inline-block">Bag</span>
              </button>
            </div>
          </div>
        </div>
      </nav>
      {(router.asPath.includes(basePath) || router.asPath.includes('/products') || router.asPath.includes('/designer')) && <CategoriesHeader categories={config.content.categories} />}
      <div className={classNames(styles.SecondHeaderContainer)}>
        <Link href={basePath} className={`${styles.MobileHeaderLink} body-18-bold ${(router.asPath.includes(basePath) || router.asPath.includes('/products') || router.asPath.includes('/designer-store')) ? `${styles.ActiveTab}` : 'bg-brandYellow'}`}>
          Shop
        </Link>
        <Link href="/clearout" className={`${styles.MobileHeaderLink} body-18-bold ${(router.asPath.includes('/clearout')) ? `${styles.ActiveTab}` : 'bg-brandYellow'}`}>
          Sell
        </Link>
      </div>
    </>
  );
};
