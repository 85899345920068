var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"f2bd458c5c6346fef840cbf606ca10e49d5b392f"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN || 'https://380baaec85cc4d2dad6b57c7e77f2085@o4504683942641664.ingest.sentry.io/4504683943624704',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0,
  ignoreErrors: [
    'Network request failed',
    'Failed to fetch',
    'Request aborted',
    'NetworkError',
    'Network Error',
    'AxiosError: Request aborted',
    'withrealtime/messaging',
    'Non-Error promise rejection captured with keys: message, name, transporterStackTrace',
    'ResizeObserver loop completed with undelivered notifications.',
  ],

  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
