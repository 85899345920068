import { CanonicalItem } from '../../data-fetching/canonicalItem';
import { useStoryblokConfig } from '../../context/ConfigContext';
import { ConfigStoryblok } from '../../component-types-sb';

import { roundNumberDownToNearest5 } from '../../utils/helpers';
import { isNotNullOrUndefined, isNullOrUndefined } from '../../utils/objectHelpers';
import { useItemWatchCount } from '../../data-fetching/useItemWatchCount';
import { calculatePercentageDiscount } from '../sections/ProductSingle/utils/helpers';

export enum ItemCalloutType {
  Promo = "Promo",
  Discount = "Discount",
  WithTags = "WithTags",
  NewIn = "NewIn",
  Under5 = "Under5",
  MostLoved = "MostLoved",
  Designer = "Designer"
}

export type ItemCallout = {
  label: string,
  // default to brandYellow
  colour: string,
  // default to right
  sidePreference: "left" | "right",
  type: ItemCalloutType,
}

export const useItemCallouts = (item: CanonicalItem): ItemCallout[] => {
  const storyblokConfig = useStoryblokConfig();
  const watchCount = useItemWatchCount(item.id);

  return [
    getCurrentPromoCallout(item, storyblokConfig),
    discountCallout(item),
    newWithTagsCallout(item),
    newInCallout(item),
    underFivePoundsCallout(item),
    mostLovedCallout(watchCount),
    designerCallout(item),
  ].filter(isNotNullOrUndefined)
}

const getCurrentPromoCallout = (item: CanonicalItem, config: ConfigStoryblok | null): ItemCallout | null => {
  const currentPromo = (config?.promo_tags ?? [])
    .map(storyblokPromo => ({
      collection: `${storyblokPromo.department}-${storyblokPromo.collection_name.trim().replace(/\s/g, "-").toLowerCase()}`,
      label: storyblokPromo.label_text,
    }))
    .find(promo => item.collections.includes(promo.collection));
  if (!currentPromo) return null;
  return {
    label: currentPromo.label,
    sidePreference: 'right',
    colour: 'brandYellow',
    type: ItemCalloutType.Promo,
  }
}

const MIN_DISCOUNT_FOR_CALLOUT = 30;
const discountCallout = (item: CanonicalItem): ItemCallout | null => {
  const compareAtPrice = item.strikethrough_price;
  if (isNullOrUndefined(compareAtPrice)) return null;
  if (compareAtPrice <= item.price) return null;
  if (isNullOrUndefined(item.listed_at)) return null;

  const ninetyDaysAgo = new Date();
  ninetyDaysAgo.setDate(ninetyDaysAgo.getDate() - 90);
  const discount = calculatePercentageDiscount(item.price, item.strikethrough_price ?? 0);

  if (discount < MIN_DISCOUNT_FOR_CALLOUT) return null;
  if (new Date(item.listed_at) > ninetyDaysAgo) return null; // must be at least 90 days old

  if (discount === 100) {
    return {
      label: 'free',
      colour: 'brandYellow',
      sidePreference: 'right',
      type: ItemCalloutType.Discount,
    }
  } else {
    return {
      label: `${discount}% off`,
      colour: 'brandYellow',
      sidePreference: 'right',
      type: ItemCalloutType.Discount,
    }
  }
}

const newWithTagsCallout = (item: CanonicalItem): ItemCallout | null => {
  if (!item.original_tags) return null;
  return {
    label: "with tags",
    colour: "brandTeal",
    sidePreference: 'left',
    type: ItemCalloutType.WithTags,
  }
}

const newInCallout = (item: CanonicalItem): ItemCallout | null => {
  if (isNullOrUndefined(item.listed_at)) return null;

  const dateItemListed = new Date(item.listed_at).getTime();
  const now = Date.now();
  const oneDayAgo = now - (1 * 24 * 60 * 60 * 1000);

  if (dateItemListed >= oneDayAgo && dateItemListed <= now) {
    return {
      label: 'new in',
      sidePreference: "left",
      colour: "brandYellow20",
      type: ItemCalloutType.NewIn,
    }
  }

  return null;
}

const underFivePoundsCallout = (item: CanonicalItem): ItemCallout | null => {
  if (item.price >= 5) return null;
  return {
    label: "under £5",
    colour: "brandYellowLight",
    sidePreference: 'left',
    type: ItemCalloutType.Under5,
  }
}

const mostLovedCallout = (watchCount: null | number): ItemCallout | null => {
  if (!watchCount || watchCount <= 3) return null;

  return {
    label: "most loved",
    colour: "brandLightRed",
    sidePreference: 'left',
    type: ItemCalloutType.MostLoved,
  }
}

const designerCallout = (item: CanonicalItem): ItemCallout | null => {
  if (item.brand_type !== "designer") return null;

  return {
    label: "verified",
    colour: 'blackAlt',
    sidePreference: 'right',
    type: ItemCalloutType.Designer,
  }
}